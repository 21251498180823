html.dark {
  background-color: rgb(15, 23, 42);
}

.safe-h-screen {
  /* equivalent to 100dvh in major browsers */
  height: calc(
    100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
  );
}

@supports (-webkit-touch-callout: none) {
  .safe-h-screen {
    /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
    height: -webkit-fill-available;
  }
}
